import React, { useState, useEffect } from 'react';
import Menu from './Menu/Menu';
import Hero from './Hero/Hero';
import About from './About/About';
import Domains from './Domains/Domains';
import OurValues from './OurValues/OurValues';
import Fees from './Fees/Fees';
import Contact from './Contact/Contact';
import Footer from './Footer/Footer';

import { PortfolioProvider } from '../context/context';

import {
  heroData,
  aboutData,
  domainsData,
  ourValuesData,
  feesData,
  contactData,
} from '../mock/data';

function App() {
  const [hero, setHero] = useState({});
  const [about, setAbout] = useState({});
  const [domains, setDomains] = useState({});
  const [ourValues, setOurValues] = useState([]);
  const [fees, setFees] = useState([]);
  const [contact, setContact] = useState({});
  const [footer, setFooter] = useState({});

  useEffect(() => {
    setHero({ ...heroData });
    setAbout({ ...aboutData });
    setDomains({ ...domainsData });
    setOurValues([...ourValuesData]);
    setFees([...feesData]);
    setContact({ ...contactData });
  }, []);

  return (
    <PortfolioProvider value={{ hero, about, domains, ourValues, fees, contact, footer }}>
      <Menu />
      <Hero />
      <About />
      <Domains />
      <OurValues />
      <Fees />
      <Contact />
      <Footer />
    </PortfolioProvider>
  );
}

export default App;
