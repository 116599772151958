import React, { useContext, useState } from 'react';
import Fade from 'react-reveal/Fade';
import { Container } from 'react-bootstrap';
import PortfolioContext from '../../context/context';
import Title from '../Title/Title';
import ContactForm from './ContactForm';
import LeafletMap from './LeafletMap';

const Contact = () => {
  const { contact } = useContext(PortfolioContext);
  const { cta, btn, email, socials, address, paragraph } = contact;
  const position = [51.505, -0.09];

  const [isDesktop, setIsDesktop] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  return (
    <section id="contact">
      <Container>
        <Title title="Contact" />
        {/* Contact form */}
        <Fade bottom={true} duration={1000} delay={400} distance="50px">
          <ContactForm />
        </Fade>
        <hr />
        {/* Social links */}
        <Fade left={isDesktop} bottom={isMobile} duration={1000} delay={1000} distance="30px">
          <div className="contact-socials">
            {socials &&
              socials.map((social, key) => {
                let url = social.url;
                let username = social.username;

                let social_url = url && username ? url + username : 'https://github.com/tgrandjean';

                return (
                  <a
                    key={key}
                    href={social_url}
                    rel={social.name === 'phone' ? 'nofollow' : 'noopener noreferrer'}
                    target={social.name === 'phone' ? '_self' : '_blank'}
                    aria-label={social.name}
                  >
                    <i className={`fa fa-${social.name || 'refresh'} fa-inverse`} />
                  </a>
                );
              })}
          </div>
        </Fade>
        {/* Map */}
        <hr />
        <p dangerouslySetInnerHTML={{ __html: address }} />
        <LeafletMap />
      </Container>
    </section>
  );
};

export default Contact;
