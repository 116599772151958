import React, { useContext, useState, useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Fade from 'react-reveal/Fade';
import { Link } from 'react-scroll';
import PortfolioContext from '../../context/context';

const Header = () => {
  const { hero } = useContext(PortfolioContext);
  const { title, name, subtitle, punchline, cta, ctaContact } = hero;

  const [isDesktop, setIsDesktop] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    if (window.innerWidth > 769) {
      setIsDesktop(true);
      setIsMobile(false);
    } else {
      setIsMobile(true);
      setIsDesktop(false);
    }
  }, []);

  return (
    <section id="hero" className="jumbotron">
      <Container>
        <Fade left={isDesktop} bottom={isMobile} duration={1000} delay={500} distance="30px">
          <h1 className="hero-title">
            {title || ''} <span className="text-color-main">{name || ''}</span>
            <br />
          </h1>
        </Fade>
        <Fade left={isDesktop} bottom={isMobile} duration={1000} delay={800} distance="30px">
          <h2 className="hero-subtitle">{subtitle || ''}</h2>
        </Fade>
        <Fade left={isDesktop} bottom={isMobile} duration={1000} delay={900} distance="30px">
          <h2 className="hero-punchline">{punchline || ''}</h2>
        </Fade>
        <Fade left={isDesktop} bottom={isMobile} duration={1000} delay={1000} distance="30px">
          <Row>
            <Col>
              <p className="hero-cta">
                <span className="cta-btn cta-btn--hero">
                  <Link to="about" smooth duration={1000} offset={-70}>
                    {cta || ''}
                  </Link>
                </span>
              </p>
            </Col>
            <Col>
              <p className="hero-cta">
                <span className="cta-btn cta-btn--hero">
                  <Link to="contact" smooth duration={1000} offset={-70}>
                    {ctaContact || ''}
                  </Link>
                </span>
              </p>
            </Col>
          </Row>
        </Fade>
      </Container>
    </section>
  );
};

export default Header;
