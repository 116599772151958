import React, { useState } from 'react';
import { Alert, Container, Col, Form, Row } from 'react-bootstrap';

const ContactForm = () => {
  const [formData, setFormData] = useState({
    first_name: '',
    last_name: '',
    phone: '',
    email: '',
    subject: '',
    message: '',
  });
  const [isSuccess, setIsSuccess] = useState(false);
  const [isLoading, setIsloading] = useState(false);
  const [isError, setIsError] = useState(false);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    grecaptcha.ready(() => {
      grecaptcha
        .execute('6LdhmWQpAAAAAMcjV5P8avJ2sOfn9jl1WclFs8xB', { action: 'submit' })
        .then((token) => {
          setIsloading(true);
          const payload = { ...formData, token };
          fetch('https://y3tuh2t8v3.execute-api.eu-west-3.amazonaws.com/default/form-processing', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(payload),
          })
            .catch((error) => {
              setIsError(true);
              console.error(error);
            })
            .then((response) => {
              if (response?.status >= 400 || response === undefined) {
                setIsError(true);
              } else {
                setIsSuccess(true);
              }
            });
        });
    });
  };

  return isSuccess ? (
    <Alert variant="success">Formulaire envoyé</Alert>
  ) : isError ? (
    <Alert variant="danger">Une erreur est survenue. Veuillez réessayer plus tard.</Alert>
  ) : isLoading ? (
    <Alert variant="info">Chargement...</Alert>
  ) : (
    <Container fluid={true} className="contact-form">
      <Row>
        <Form id="contactForm" onSubmit={handleSubmit}>
          <Row className="mb-3">
            <Form.Group as={Col} controlId="formFirstName">
              <Form.Label>Prénom</Form.Label>
              <Form.Control
                size="lg"
                type="text"
                name="first_name"
                onChange={handleChange}
                placeholder="Jean"
                required
              />
            </Form.Group>
            <Form.Group as={Col} controlId="formLastName">
              <Form.Label>Nom</Form.Label>
              <Form.Control
                size="lg"
                type="text"
                name="last_name"
                placeholder="Dupond"
                required={true}
                onChange={handleChange}
              />
            </Form.Group>
          </Row>

          <Row className="mb-3">
            <Form.Group as={Col} controlId="formPhone">
              <Form.Label>Téléphone</Form.Label>
              <Form.Control
                size="lg"
                type="tel"
                name="phone"
                placeholder="06 12 34 56 78"
                required={true}
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group as={Col} controlId="formEmail">
              <Form.Label>E-mail</Form.Label>
              <Form.Control
                size="lg"
                type="email"
                name="email"
                placeholder="jean.dupond@email.com"
                required
                onChange={handleChange}
              />
            </Form.Group>
          </Row>
          <Row className="mb-3">
            <Form.Group as={Col} controlId="formSubject">
              <Form.Label>Sujet</Form.Label>
              <Form.Control size="lg" name="subject" required as="select" onChange={handleChange}>
                <option></option>
                <option>Demande de contact</option>
                <option>Prise de rendez-vous</option>
                <option>Postulation</option>
              </Form.Control>
            </Form.Group>
          </Row>
          <Row className="mb-3">
            <Form.Group as={Col} controlId="formMessage">
              <Form.Label>Nom</Form.Label>
              <Form.Control
                size="lg"
                as="textarea"
                name="message"
                placeholder="Votre message ici."
                rows={5}
                onChange={handleChange}
                required
              />
            </Form.Group>
          </Row>
          <Row style={{ textAlign: 'center' }}>
            <button className="cta-btn cta-btn--resume g-recaptcha">Envoyer</button>
          </Row>
        </Form>
      </Row>
    </Container>
  );
};

export default ContactForm;
