import React from 'react';
import { Container, Nav, Navbar } from 'react-bootstrap';
import { scroller } from 'react-scroll';

const Menu = () => {
  return (
    <Navbar fixed="top" bg="dark" variant="dark" expand="lg">
      <Container>
        <Navbar.Brand href="#">Mireille CANABY Avocat</Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav" className="justify-content-end">
          <Nav className="ml-auto">
            <Nav.Link
              onClick={() =>
                scroller.scrollTo('about', {
                  smooth: true,
                  offset: -70,
                  duration: 500,
                })
              }
            >
              Accueil
            </Nav.Link>
            <Nav.Link
              onClick={() =>
                scroller.scrollTo('domains', {
                  smooth: true,
                  offset: -70,
                  duration: 500,
                })
              }
            >
              Domaines d'intervention
            </Nav.Link>
            <Nav.Link
              onClick={() =>
                scroller.scrollTo('ourValues', {
                  smooth: true,
                  offset: -70,
                  duration: 500,
                })
              }
            >
              Nos valeurs
            </Nav.Link>
            <Nav.Link
              onClick={() =>
                scroller.scrollTo('fees', {
                  smooth: true,
                  offset: -70,
                  duration: 500,
                })
              }
            >
              Honoraires
            </Nav.Link>
            <Nav.Link
              onClick={() =>
                scroller.scrollTo('contact', {
                  smooth: true,
                  offset: -70,
                  duration: 500,
                })
              }
            >
              Contact
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default Menu;
