import React from 'react';
import { Helmet } from 'react-helmet';
import App from '../components/App';
import { JsonLD } from '../components/JsonLD/JsonLD';
import { headData } from '../mock/data';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../style/main.scss';

const Index = () => {
  const { title, lang, description } = headData;

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{title || 'Gatsby Simplefolio'}</title>
        <html lang={lang || 'fr'} />
        <meta name="description" content={description || 'Gatsby Simplefolio'} />
        <JsonLD>
          {{
            '@context': 'https://schema.org',
            '@type': 'Organization',
            url: 'https://wwww.canaby-avocat-perpignan.fr',
            name: 'Mireille CANABY Avocat: spécialiste en droit de la famille, des personnes et de leur patrimoine.',
            contactPoint: {
              '@type': 'ContactPoint',
              telephone: '+33468223514',
              contactType: 'Customer service',
            },
          }}
        </JsonLD>
      </Helmet>
      <App />
    </>
  );
};

export default Index;
