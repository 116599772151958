import React from 'react';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';

const LeafletMap = () => {
  const position = [42.70147, 2.8899];

  if (typeof window !== 'undefined') {
    let icon = L.icon({
      iconUrl: require('leaflet/dist/images/marker-icon.png').default,
      iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png').default,
      shadowUrl: require('leaflet/dist/images/marker-shadow.png').default,
      iconSize: [25, 41],
      iconAnchor: [12, 40],
    });

    return (
      <MapContainer center={position} zoom={18} scrollWheelZoom={true} tap={false}>
        <TileLayer
          attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        <Marker icon={icon} position={position}>
          <Popup>
            Me Mireille CANABY
            <br />
            19 espace Méditéranée, 66000 Perpignan.
          </Popup>
        </Marker>
      </MapContainer>
    );
  }
  return null;
};

export default LeafletMap;
