import React, { useContext, useState, useEffect } from 'react';
import Fade from 'react-reveal/Fade';
import { Accordion, Button, Card, Container, Row, Col } from 'react-bootstrap';
import Title from '../Title/Title';
import PortfolioContext from '../../context/context';

const Domains = () => {
  const { domains } = useContext(PortfolioContext);
  const { description, domainsArray } = domains;
  const [isDesktop, setIsDesktop] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    if (window.innerWidth > 769) {
      setIsDesktop(true);
      setIsMobile(false);
    } else {
      setIsMobile(true);
      setIsDesktop(false);
    }
  }, []);

  return (
    <section id="domains">
      <Container>
        <Row className="domains-wrapper">
          <Title title="Domaines d'intervention" />
          <Fade right={isDesktop} bottom={isMobile} duration={1000} delay={200} distance="10px">
            <Col md={12} sm={12}>
              <div className="domains-wrapper__info">
                <p className="domains-wrapper__info-text">{description}</p>
              </div>
            </Col>
          </Fade>

          <Accordion className="domains-wrapper__accordion">
            {domainsArray &&
              domainsArray.map((domain, index) => (
                <Fade
                  right={isDesktop}
                  bottom={isMobile}
                  duration={1000}
                  delay={200 + index * 10}
                  distance="50px"
                  key={index}
                >
                  <Card>
                    <Accordion.Toggle as={Card.Header} eventKey={`${index}`}>
                      <p>{domain.name}</p>
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey={`${index}`}>
                      <Card.Body>
                        <p>{domain.description}</p>
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                </Fade>
              ))}
          </Accordion>
        </Row>
      </Container>
    </section>
  );
};

export default Domains;

// <Card>
//   <Accordion.Toggle as={Card.Header} eventKey="0">
//     <p className="domains-wrapper__info-accordion">Click me!</p>
//   </Accordion.Toggle>
//   <Accordion.Collapse eventKey="0">
//     <Card.Body>
//       <p className="domains-wrapper__info-accordion">Hello! I'm the body</p>
//     </Card.Body>
//   </Accordion.Collapse>
// </Card>
